<template>
    <v-container fluid>
        <v-row>
            <v-col cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>Consulta de Produto</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="12"
                lg="4"
                md="4"
                sm="6"
            >
                <label>Código do Produto</label>
                <v-text-field
                    v-model="codigoProduto"
                    append-outer-icon="mdi mdi-magnify"
                    hint="Pesquise diretamente pelo código do produto ou utilize os filtros abaixo"
                    :persistent-hint=true
                    single-line
                    outlined
                    @keyup.enter="getListProduto()"
                    @click:append-outer="getListProduto()"
                >
                </v-text-field>
            </v-col>
            <v-col 
                cols="12"
                lg="8"
                md="8"
                sm="6"
                style="text-align: right;"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            @click="novaBusca()"
                        >
                            Nova Busca
                        </v-btn>
                    </template>
                    <span>Nova Busca</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
            <v-col 
                cols="12"
                lg="6"
                md="6"
                sm="6"
            >
                <label>Grandeza</label>
                <v-combobox
                    v-model="grandezaSelected"
                    :items="listGrandeza"
                    item-text="descricao"
                    item-value="id"
                    clearable
                    outlined
                    @change="getListParametros(); getListTipoProduto(); getListMarca()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="6"
                md="6"
                sm="6"
            >
                <label>Marca</label>
                <v-combobox
                    v-model="marcaSelected"
                    :items="listMarca"
                    item-text="descricao"
                    item-value="id"
                    clearable
                    outlined
                    @change="getListParametros(); getListTipoProduto()"
                ></v-combobox>
            </v-col>

            <v-col 
                cols="12"
                lg="6"
                md="6"
                sm="6"
            >
                <label>Tipo de Produto</label>
                <v-combobox
                    v-model="tipoProdutoSelected"
                    :items="listTipoProduto"
                    item-text="descricao"
                    item-value="id"
                    clearable
                    outlined
                    @change="getListParametros(); getListFamilias(false, '');"
                ></v-combobox>
            </v-col>

            <v-col 
                cols="12"
                lg="6"
                md="6"
                sm="6"
            >
                <label>Familía</label>
                <v-combobox
                    v-model="familiaProdutoSelected"
                    :items="listFamilias"
                    item-text="codigoFamilia"
                    item-value="id"
                    clearable
                    outlined
                    @change="getListParametros()"
                >
                    <template v-slot:item="{ item }">
                        <span>{{ item.codigoFamilia }}</span>
                        <v-spacer></v-spacer>
                        <v-tooltip
                            v-if="item.obsoleto == 1"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-icon color="var(--color__warning)" large v-on="on">mdi-alert-rhombus</v-icon>
                            </template>
                            <span> {{ item.textoObsoleto  }} </span>
                        </v-tooltip>
                    </template>
                </v-combobox>
            </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
            <v-col 
                v-for="(itemParametro, index) in listParametro" :key="index"
                cols="6"
                lg="3"
                md="6"
                sm="6"
            >
                <label style="font-size: 12px;" v-if="itemParametro.descricao != null && itemParametro.descricao != ''">{{ itemParametro.descricao }}</label>
                <v-combobox
                    v-if="itemParametro.descricao != null && itemParametro.descricao != '' && itemParametro.listParametroItem != null && itemParametro.listParametroItem.length > 0"
                    v-model="itemParametro.itemSelected"
                    :items="itemParametro.listParametroItem.filter(item => item.mostrar == true)"
                    item-text="codigoDescricao"
                    item-value="codigo"
                    clearable
                    outlined
                    @change="verificaListaParametro(false);verificaParametrosPreenchidos();"
                >
                    <template v-slot:item="{ item }">
                        <span>{{ item.codigoDescricao }}</span>
                        <v-spacer></v-spacer>
                        <v-tooltip
                            v-if="item.obsoleto == 1"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-icon color="var(--color__warning)" large v-on="on">mdi-alert-rhombus</v-icon>
                            </template>
                            <span> {{ item.textoObsoleto  }} </span>
                        </v-tooltip>
                    </template>                
                </v-combobox>
            </v-col>
        </v-row>
    
        <v-row
            v-if="todosParametrosPreenchidos || listProduto.length == 0"
        >
            <v-col 
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-list shaped>
                    <v-subheader>
                        {{ getTextoConsulta(listProduto).descricao }}
                        
                        <v-tooltip 
                            v-if="getTextoConsulta(listProduto).mostrarBotao"
                            left
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    style="margin-left: 10px;"
                                    v-if="getTextoConsulta(listProduto).mostrarBotao"
                                    large
                                    v-on="on"
                                    @click="confirmarSolicitacaoCadastroProduto()"
                                >
                                    <v-icon left>mdi-email-fast-outline</v-icon>
                                    Solicitar
                                </v-btn>
                            </template>
                            <span>Será enviado um e-mail com a solicitação de cadastro do produto</span>
                        </v-tooltip>

                    </v-subheader>

                    <v-list-item-group
                    >
                        <v-list-item
                            v-for="(item, index) in listProduto"
                            :key="index"
                        >
                            <v-list-item-avatar>
                                <v-img :src="item.imagem"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content style="flex: 4;">
                                <v-list-item-title><strong> {{ item.codigoCompleto }} </strong></v-list-item-title>
                                <v-list-item-subtitle>{{ item.descricao }}</v-list-item-subtitle>

                                <!-- OUTROS CUSTOS -->
                                <v-list-item-subtitle 
                                    v-if="userLoggedGetters.usuarioInterno == 1"
                                    style="margin-top: 5px;"
                                >
                                    <div 
                                        class="detalhes-produto"
                                    >
                                        <div style="flex: 3"> <span><strong>Origem</strong></span> </div>
                                        <div style="flex: 3; text-align: left;"> <span>{{ item.paisCidadeDescricao }}</span> </div>
                                    </div>
                                    <div 
                                        v-if="userLoggedGetters.perfil.visualizarMargemEfetiva == 1"
                                        class="detalhes-produto"
                                    >
                                        <div style="flex: 3"> <span><strong>Custo Base</strong></span> </div>
                                        <div style="flex: 3; text-align: left;"> <span>{{ item.simboloCustoBase }} {{ item.precoCustoProdutoSemMargemFormatted }}</span> </div>
                                    </div>
                                    <div 
                                        v-if="userLoggedGetters.perfil.visualizarMargemEfetiva == 1 && item.importado == 1"
                                        class="detalhes-produto"
                                    >
                                        <div style="flex: 3"> <span><strong>Margem</strong></span> </div>
                                        <div style="flex: 3; text-align: left;"> <span>{{ item.margemFormatted }} %</span> </div>
                                    </div>
                                    <div 
                                        v-if="mostrarValorICP && item.importado == 1" 
                                        class="detalhes-produto"
                                    >
                                        <div style="flex: 3"> <span><strong>Valor ICP Base</strong></span> </div>
                                        <div style="flex: 3; text-align: left;"> <span>{{ item.valorICPFormatted }}</span> </div>
                                    </div>
                                    <div 
                                        v-if="item.certificacaoEX == 1" 
                                        class="detalhes-produto"
                                    >
                                        <v-tooltip
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-img 
                                                    v-on="on" 
                                                    :src="require('@/assets/images/certificacaoex.png')" max-height="25" max-width="25" 
                                                />
                                            </template>
                                            <span>Produto com Certificação EX</span>
                                        </v-tooltip>
                                    </div>
                                </v-list-item-subtitle>


                                <!-- SUB-ESTRUTURAS -->
                                <v-list-item-subtitle 
                                    v-if="mostrarSubEstruturas(item)"
                                    style="margin-top: 5px;"
                                >
                                    <div style="width: 50%; display: flex; flex-direction: row;">
                                        <div style="flex: 6"> <span><strong>Subestruturas Utilizadas</strong></span> </div>
                                        <div style="flex: 3; text-align: right;"> <span><strong>Custo</strong></span> </div>
                                        <div style="flex: 3; text-align: right;"> <span><strong>Custo + Margem</strong></span> </div>
                                    </div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                    v-if="mostrarSubEstruturas(item)"
                                >
                                    <div style="width: 50%; display: flex; flex-direction: row;" v-for="(itemSub, index) in item.listSubEstrutura.filter(sub => sub.regraAplicada == true)" :key="index">
                                        <div style="flex: 6"> <span>{{ itemSub.nome }}</span> </div>
                                        <div style="flex: 3; text-align: right;"> <span>{{ itemSub.custoFormatted }}</span> </div>
                                        <div style="flex: 3; text-align: right;"> <span>{{ itemSub.custoComMargemFormatted }}</span> </div>
                                        <br />
                                    </div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                    v-if="mostrarSubEstruturas(item)"
                                >
                                    <div style="width: 50%; display: flex; flex-direction: row;">
                                        <div style="flex: 6"> <span><strong>Totais</strong></span> </div>
                                        <div style="flex: 3; text-align: right;"> <span><strong>{{ valorTotalSubEstruturas(item).valorTotal }}</strong></span> </div>
                                        <div style="flex: 3; text-align: right;"> <span><strong>{{ valorTotalSubEstruturas(item).valorTotalComMargem }}</strong></span> </div>
                                    </div>
                                </v-list-item-subtitle>


                                <!-- PARAMETROS -->
                                <v-list-item-subtitle 
                                    v-if="mostrarParametros(item)"
                                    style="margin-top: 5px;"
                                >
                                    <div style="width: 60%; display: flex; flex-direction: row;">
                                        <div style="flex: 9"> <span><strong>Parametros Utilizados</strong></span> </div>
                                        <div style="flex: 4; text-align: right;"> <span><strong>Custo s/ Margem</strong></span> </div>
                                        <div style="flex: 4; text-align: right;"> <span><strong>Custo c/ Margem</strong></span> </div>
                                        <div style="flex: 3; text-align: right;"> <span><strong>Valor ICP</strong></span> </div>
                                    </div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                    v-if="mostrarParametros(item)"
                                >
                                    <div style="width: 60%; display: flex; flex-direction: row;" v-for="(itemParam, index) in listParametro.filter(itemParam => itemParam.itemSelected != null && itemParam.itemSelected != undefined)" :key="index">
                                        <div style="flex: 9; display: flex;"> 
                                            <span>{{ itemParam.itemSelected.codigo }}</span>
                                            <!-- <v-icon v-if="itemParam.itemSelected.certificacaoEX == 1" color="var(--color__warning)" x-small right>mdi-certificate-outline</v-icon> -->
                                            <v-img v-if="itemParam.itemSelected.certificacaoEX == 1" style="margin-left: 10px;" :src="require('@/assets/images/certificacaoex.png')" max-height="15" max-width="15" />
                                        </div>
                                        <div style="flex: 4; text-align: right;"> <span>{{ itemParam.itemSelected.precoCustoParametroItemSemMargemFormatted }}</span> </div>
                                        <div style="flex: 4; text-align: right;"> <span>{{ itemParam.itemSelected.precoCustoParametroItemFormatted }}</span> </div>
                                        <div style="flex: 3; text-align: right;"> <span>{{ itemParam.itemSelected.valorICPFormatted }}</span> </div>
                                        <br />
                                    </div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                    v-if="mostrarParametros(item)"
                                >
                                    <div style="width: 60%; display: flex; flex-direction: row;">
                                        <div style="flex: 9"> <span><strong>Totais</strong></span> </div>
                                        <div style="flex: 4; text-align: right;"> <span><strong>{{ valorTotalParametrosSelecionados(item, false).valorTotalSemMargem }}</strong></span> </div>
                                        <div style="flex: 4; text-align: right;"> <span><strong>{{ valorTotalParametrosSelecionados(item, false).valorTotalComMargem }}</strong></span> </div>
                                        <div style="flex: 3; text-align: right;"> <span><strong>{{ valorTotalParametrosSelecionados(item, false).valorTotalICPFormatted }}</strong></span> </div>
                                    </div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                    v-if="mostrarParametros(item)"
                                >
                                    <div style="width: 60%; display: flex; flex-direction: row;">
                                        <div style="flex: 9"> <span><strong>Custo Base + Custo Parametros</strong></span> </div>
                                        <div style="flex: 4; text-align: right;"> <span><strong>{{ valorTotalParametrosSelecionados(item, true).valorTotalSemMargem }}</strong></span> </div>
                                        <div style="flex: 4; text-align: right;"> <span><strong></strong></span> </div>
                                        <div style="flex: 3; text-align: right;"> <span><strong>{{ valorTotalParametrosSelecionados(item, true).valorTotalICPFormatted }}</strong></span> </div>
                                    </div>
                                </v-list-item-subtitle>

                            </v-list-item-content>

                            <v-list-item-content>
                                <v-list-item-title 
                                    style="color: var(--color__main) !important; font-size: 25px; text-align: right; margin-right: 20px;"
                                >
                                    <strong> {{ precoFinalProduto(item) }} </strong>
                                </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action
                                v-if="item.obsoleto == 1"
                                style="width: 83px; justify-content: center;"
                            >
                                <v-tooltip
                                    v-model="sempreMostrar"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__warning)" 
                                            large 
                                            v-on="on"
                                        >mdi-alert-rhombus</v-icon>
                                    </template>
                                    <span> {{ item.textoObsoleto  }} </span>
                                </v-tooltip>
                            </v-list-item-action>

                            <v-list-item-action
                                v-if="item.obsoleto != 1"
                            >
                                <v-tooltip 
                                    v-if="todosParametrosPreenchidos"
                                    left
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            icon
                                            v-on="on"
                                            @click="adicionarCarrinho(item)"
                                        >
                                            <v-icon>mdi-cart</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Adicionar ao carrinho</span>
                                </v-tooltip>
                            </v-list-item-action>

                            <v-list-item-action
                                v-if="item.obsoleto != 1"
                            >
                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            icon
                                            v-on="on"
                                            @click="detalhesProduto(item)"
                                        >
                                            <v-icon>mdi-information</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Clique para mais informações</span>
                                </v-tooltip>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>

        <v-divider></v-divider>
        
		<v-dialog
			v-model="mostrarDetalhesProduto"
			transition="dialog-top-transition"
			width="1000"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
        
            <v-card v-if="produtoSelected != null && produtoSelected != undefined">
                <v-card-title class="text-h5 lighten-2">
                    Detalhes do Produto: {{ produtoSelected != null ? produtoSelected.codigoCompleto : '' }}
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="4"
                            md="6"
                            sm="12"
                        >
                            <v-row>
                                <v-col 
                                    style="text-align: center;"
                                    cols="12"
                                >
                                    <v-card>
                                        <label>Imagem do Produto</label>
                                        <v-avatar :tile="true" size="100%">
                                            <canvas id="canvas" hidden></canvas>
                                            <img :src="produtoSelected.imagem" alt="avatar" style="height: 250px" />
                                        </v-avatar>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="8"
                            md="6"
                            sm="12"
                        >
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="6"
                                >
                                    <label>Descrição</label>
                                    <v-textarea
                                        v-model="produtoSelected.descricao"
                                        readonly
                                        single-line
                                        rows="3"
                                        row-height="30"
                                        outlined
                                    >
                                    </v-textarea>
                                </v-col>
                                <v-col 
                                    style="margin-top: -20px;"
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="6"
                                >
                                    <a v-bind:href="produtoSelected.urlPdf" target="_blank">Link PDF</a>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="6"
                                >
                                    <label>Especificações</label>
                                    <v-simple-table
                                        fixed-header
                                    >
                                        <template v-slot:default>
                                            <tbody>
                                                <tr
                                                    v-for="(itemEspecificacao, index) in listEspecificacao" :key="index"
                                                >
                                                    <td><strong>{{ itemEspecificacao.chave }}</strong></td>
                                                    <td>{{ itemEspecificacao.descricao }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-card-text>

                <v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">

						<v-btn
							class="mx-2 botaoDispensar"
							outlined
							@click="OK()"
						>
							FECHAR
						</v-btn>
						<v-btn
							class="mx-2"
							outlined
							@click="adicionarCarrinho(produtoSelected); OK();"
						>
							<v-icon left>mdi-cart</v-icon> ADICIONAR NO CARRINHO
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <ActionDialog 
            v-on:methodAcao1ToCall="dialog.methodAcao1"
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :button1Text="dialog.button1Text"
            :button2Text="dialog.button2Text"
            :dismissDialog="dialog.dismissDialog"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { formaterDecimalBRServer, formaterDecimalBR, removeAcentos } from '@/utilities/Utils';

    export default ({

        mixins: [Helpers],

        components: {
            ActionDialog: ActionDialog
        },

        data: () => ({

            codigoProduto: "",

            listTipoProduto: [],
            listMarca: [],
            listGrandeza: [],

            grandezaSelected: null,
            marcaSelected: null,
            tipoProdutoSelected: null,
            familiaProdutoSelected: null,
            produtoSelected: null,
            descricaoProdutoCompleta: "",

            listProduto: [],
            listParametro: [],
            listEspecificacao: [],
            listProdutoKeyAccount: [],

            mostrarDetalhesProduto: false,
            precoCustoProdutoCalculado: 0,
            listaIDParametroItem: '',

            jsonParametrosSelecionados: "",

            listFamilias: [],
            listFamiliasAux: [],

            sempreMostrar: true,
            
            og: Math.pow(10, 2),

            todosParametrosPreenchidos: false,

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                button1Text: "Cancelar",
                button2Text: "Confirmar",
                methodAcao1: ( () => {} ),
                methodConfirm: ( () => {} ),
                dismissDialog: true,
                params: null
            },
        }),
        
        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
            ...mapGetters('carrinhoModule', { carrinhoStateGetters: 'carrinhoState' }),

            mostrarValorICP() {
                return this.userLoggedGetters.perfil.visualizarICP == 1;
            }
        },

        methods: {

            getTextoConsulta(listProduto) {
                if (listProduto.length == 0 && this.codigoProduto != '') {
                    return  {
                        mostrarBotao: true,
                        descricao: 'Nenhum produto encontrado. Deseja solicitar o cadastro desse código? Clique no botão ao lado. '
                    }                        
                }
                else if (listProduto.length == 0) {
                    return  {
                        mostrarBotao: false,
                        descricao: 'Nenhum produto encontrado.'
                    }
                }
                else { 
                    return  {
                        mostrarBotao: false,
                        descricao: 'Lista de Produtos'
                    }                    
                }
            },

            async verificaParametrosPreenchidos() {
                
                let qtdeParametrosVazio = this.listParametro.filter(item => item.itemSelected == null || item.itemSelected == undefined).length;

                this.todosParametrosPreenchidos = qtdeParametrosVazio <= 0 || this.listParametro.length <= 0;
            },

            mostrarSubEstruturas(itemProduto) {
                let mostrar = false;

                if (itemProduto.listSubEstrutura != null && 
                    itemProduto.listSubEstrutura != undefined && 
                    itemProduto.listSubEstrutura.length > 0 &&
                    itemProduto.listSubEstrutura.filter(sub => sub.regraAplicada == true).length > 0 &&
                    this.userLoggedGetters.usuarioInterno == 1 &&
                    this.userLoggedGetters.perfil.visualizarCustoProduto == 1) {
                    mostrar = true;
                }

                return mostrar;
            },

            mostrarParametros(itemProduto) {
                return this.userLoggedGetters.usuarioInterno == 1 &&
                       this.userLoggedGetters.perfil.visualizarCustoProduto == 1 &&
                       this.mostrarSubEstruturas(itemProduto) == false && 
                       this.listParametro != null && 
                       this.listParametro != undefined && 
                       this.listParametro.length > 0;
            },

            valorTotalSubEstruturas(itemProduto) {

                let valorTotal = 0;
                let valorTotalComMargem = 0;
                let margemSubEstrutura = 0;

                if (this.familiaProdutoSelected != null && this.familiaProdutoSelected != undefined) {
                    margemSubEstrutura = 1 - ( parseFloat(this.familiaProdutoSelected.margem) / 100 );
                }

                itemProduto.listSubEstrutura.filter(sub => sub.regraAplicada == true).forEach(itemSub => {
                    valorTotal += parseFloat(itemSub.custo);
                });

                valorTotal =  Math.floor(valorTotal * this.og) / this.og;
                valorTotalComMargem =  Math.floor( ( valorTotal / margemSubEstrutura) * this.og) / this.og;

                return { 
                    valorTotal: `R$ ${formaterDecimalBR(valorTotal)}`,
                    valorTotalComMargem: `R$ ${formaterDecimalBR(valorTotalComMargem)}`,
                };
            },

            valorTotalParametrosSelecionados(itemProduto, somaCustoBase) {

                let valorTotalSemMargem = 0;
                let valorTotalComMargem = 0;
                let valorTotalICP = 0;
                let moedaICP = itemProduto.simboloICP;
                let idCambioICP = itemProduto.idCambioICP;

                this.listParametro.forEach(itemParam => {
                    if (itemParam.itemSelected != null && itemParam.itemSelected != undefined) {
                        valorTotalSemMargem += parseFloat(itemParam.itemSelected.precoCustoParametroItemSemMargem);
                        valorTotalComMargem += parseFloat(itemParam.itemSelected.precoCustoParametroItem);
                        valorTotalICP += parseFloat(itemParam.itemSelected.valorICP);

                        if (moedaICP == itemProduto.simboloICP && valorTotalICP > 0) {

                            if (itemParam.itemSelected.cambioICPSelected != null && itemParam.itemSelected.cambioICPSelected != undefined && itemParam.itemSelected.cambioICPSelected.id != 0) {
                                idCambioICP = itemParam.itemSelected.cambioICPSelected.id;
                                moedaICP = itemParam.itemSelected.cambioICPSelected.simbolo;
                            }
                        }
                    }
                });

                if (somaCustoBase == true) {
                    valorTotalSemMargem += parseFloat(itemProduto.precoCustoProdutoSemMargem);
                    valorTotalComMargem += parseFloat(itemProduto.precoCustoProdutoSemMargem);
                    valorTotalICP += parseFloat(itemProduto.valorICP);
                }

                valorTotalSemMargem =  Math.floor(valorTotalSemMargem * this.og) / this.og;
                valorTotalComMargem =  Math.floor(valorTotalComMargem * this.og) / this.og;
                valorTotalICP =  Math.floor(valorTotalICP * this.og) / this.og;

                return { 
                    valorTotalSemMargem: `R$ ${formaterDecimalBR(valorTotalSemMargem)}`,
                    valorTotalComMargem: `R$ ${formaterDecimalBR(valorTotalComMargem)}`,
                    idCambioICP,
                    valorTotalICP,
                    valorTotalICPFormatted: `${moedaICP} ${formaterDecimalBR(valorTotalICP)}`,
                };
            },

            async montaJsonListaParametrosSelecionados() {

                this.jsonParametrosSelecionados = `{`;

                this.listParametro.forEach(itemParametro => {
                    if (itemParametro.itemSelected && itemParametro.itemSelected != null && itemParametro.itemSelected != undefined) {
                        this.jsonParametrosSelecionados += `"${removeAcentos(itemParametro.itemSelected.parametro.toString().replaceAll(" ", ""))}":"${itemParametro.itemSelected.codigo}",`;
                    }
                });

                this.jsonParametrosSelecionados = this.jsonParametrosSelecionados.substring(0, this.jsonParametrosSelecionados.length - 1);

                this.jsonParametrosSelecionados += `}`;
            },

            async verificaListaParametro(preenchimentoAutomatico = false) {

                await this.montaJsonListaParametrosSelecionados();

                await this.listParametro.forEach(async parametro => {
                    
                    if (preenchimentoAutomatico == false) {
                        let listItemParametro = parametro.listParametroItem.filter(item => item.mostrar == true);

                        if (listItemParametro != null && listItemParametro != undefined && listItemParametro.length == 1) {
                            parametro.itemSelected = listItemParametro[0];
                        }
                    }

                    await parametro.listParametroItem.forEach(async item => {

                        if (item.mostrar == null || item.mostrar == undefined) {
                            item.mostrar = true;
                        }

                        if (item.expressaoRegular != null && item.expressaoRegular != undefined && item.expressaoRegular != '') {

                            let expr = item.expressaoRegular.toString().replaceAll("=", "==");

                            // eslint-disable-next-line no-undef
                            let expression = compileExpression(expr);

                            let result = expression(JSON.parse(this.jsonParametrosSelecionados));
                            item.mostrar = result == 1;
                        }
                    });

                    this.$forceUpdate();
                });
                
                //VALIDA SE TEM ALGUM PARAMETRO SELECIONADO INVÁLIDO
                this.listParametro.forEach(async parametro => {

                    if (parametro.itemSelected && parametro.itemSelected != null && parametro.itemSelected != undefined) {

                        let existeNaLista = parametro.listParametroItem.filter(item => item.mostrar == true && item.id == parametro.itemSelected.id)[0];

                        if (existeNaLista == null || existeNaLista == undefined || existeNaLista.length == 0) {
                            parametro.itemSelected = null;
                            this.showToast("error", "Aviso!", `Por favor escolha uma nova opção para ${parametro.descricao}`);
                        }
                    }
                });

                this.getListProduto(); 
                this.calcularPrecoCustoProduto();
            },

            async getListTipoProduto() {

                this.showLoading();

                this.tipoProdutoSelected = null;
                
                let tipoProdutoFilter = {
                    idGrandeza: 0,
                    idMarca: 0
                }

                if (this.grandezaSelected != null && this.grandezaSelected != undefined) {
                    tipoProdutoFilter.idGrandeza = this.grandezaSelected.id;
                }

                if (this.marcaSelected != null && this.marcaSelected != undefined) {
                    tipoProdutoFilter.idMarca = this.marcaSelected.id;
                }

                this.listTipoProduto = await this.$store.dispatch("tipoProdutoModule/ListByFilter", tipoProdutoFilter);

                this.hideLoading();
            },

            async getListMarca() {

                let idGrandeza = 0;
                this.marcaSelected = null;

                if (this.grandezaSelected != null && this.grandezaSelected != undefined) {
                    idGrandeza = this.grandezaSelected.id;
                }

                let listMarca = await this.$store.dispatch("marcaModule/ListByGrandeza", idGrandeza);

                this.listMarca = listMarca.filter(mar => mar.comercializar == this.userLoggedGetters.usuarioInterno == 1 ? mar.comercializar : 1);
            },
            
            async getListParametros() {

                this.getListProduto();

                this.showLoading();

                this.listParametro = [];

                let listaProdutoBaseFilter = {
                    idTipoProduto: 0,
                    idProdutoFamilia: 0,
                }

                if (this.tipoProdutoSelected != null && this.tipoProdutoSelected != undefined) {
                    listaProdutoBaseFilter.idTipoProduto = this.tipoProdutoSelected.id;
                }

                if (this.familiaProdutoSelected != null && this.familiaProdutoSelected != undefined) {
                    listaProdutoBaseFilter.idProdutoFamilia = this.familiaProdutoSelected.id;
                }

                this.listParametro = await this.$store.dispatch("produtoModule/ListarParametrosByTipoProduto", listaProdutoBaseFilter);

                //VERIFICAR SE O PRIMEIRO PARAMETROS TEM SOMENTE UMA OPÇÃO E PREENCHE AUTOMATICAMENTE
                if (this.listParametro != null && this.listParametro != undefined && this.listParametro.length > 0) {
                    let itemParametro = this.listParametro[0];
                    let listItemParametro = itemParametro.listParametroItem.filter(item => item.mostrar == true);

                    if (listItemParametro != null && listItemParametro != undefined && listItemParametro.length == 1) {
                        itemParametro.itemSelected = listItemParametro[0];
                    }
                }

                this.hideLoading();
            },

            async verificarProdutoColado() {

                if (this.listFamiliasAux.length <= 0) {
                    let codigoProduto = ''
                    for (const c of this.codigoProduto) {
                        codigoProduto += c;
                        await this.getListFamilias(true, codigoProduto);
                    }
                }
            },

            async getListFamilias(filtrarPorCodigo, codigoProduto) {

                this.familiaSelected = null;

                if (codigoProduto == null || codigoProduto == undefined || codigoProduto == '') {
                    codigoProduto = this.codigoProduto;
                }

                let listaProdutoBaseFilter = {
                    idTipoProduto: 0,
                    codigoProduto: codigoProduto
                }

                if (filtrarPorCodigo == false && this.tipoProdutoSelected != null && this.tipoProdutoSelected != undefined) {
                    listaProdutoBaseFilter.idTipoProduto = this.tipoProdutoSelected.id;
                }

                this.listFamilias = await this.$store.dispatch("produtoModule/ListarFamilias", listaProdutoBaseFilter);

                if (filtrarPorCodigo == true && this.listFamilias.length > 0) {
                    this.listFamiliasAux = [...this.listFamilias]

                    let familia = this.listFamiliasAux[0]

                    if (familia != null && familia != undefined) {
                        
                        let grandezaSelected = this.listGrandeza.filter(grd => grd.id == familia.idGrandeza)[0];
                        this.grandezaSelected = grandezaSelected;

                        await this.getListMarca();

                        let marcaSelected = this.listMarca.filter(mar => mar.id == familia.idMarca)[0];
                        this.marcaSelected = marcaSelected;
                        
                        await this.getListTipoProduto();

                        let tipoProdutoSelected = this.listTipoProduto.filter(tip => tip.id == familia.idTipoProduto)[0]
                        this.tipoProdutoSelected = tipoProdutoSelected;

                        let familiaProdutoSelected = this.listFamilias.filter(fam => fam.id == familia.id)[0]
                        this.familiaProdutoSelected = familiaProdutoSelected;

                        this.$forceUpdate();

                        await this.getListParametros();
                    }
                }
                else {
                    if (this.listFamiliasAux.length > 0) {

                        let familia = this.listFamiliasAux[0]
                        let lengthCodigoFamilia = familia.codigoFamilia.toString().length

                        let lengthCodigoParametro = 0;
                        this.listParametro.forEach(item => {
                            if (item.itemSelected != null && item.itemSelected != undefined) {
                                lengthCodigoParametro += item.itemSelected.codigo.toString().length;
                            }
                        })

                        let codigoProduto = this.codigoProduto.substring(lengthCodigoFamilia + lengthCodigoParametro)

                        let codigoParametro = ""
                        for (const c of codigoProduto) {
                            codigoParametro += c.toString();

                            let index = this.listParametro.length - this.listParametro.filter(item => item.itemSelected == null || item.itemSelected == undefined).length;

                            if (this.listParametro != null && this.listParametro != undefined && this.listParametro.length > 0) {

                                let parametroSelected = this.listParametro[index].listParametroItem.filter(item => item.mostrar == true && item.codigo.toString().toLowerCase() == codigoParametro.toString().toLowerCase())[0]

                                if (parametroSelected != null && parametroSelected != undefined) {
                                    codigoParametro = "";
                                    this.listParametro[index].itemSelected = parametroSelected;
                                    this.verificaListaParametro(true);
                                    this.verificaParametrosPreenchidos();
                                }
                            }
                        }
                    }
                }
            },
            
            async getListProduto() {

                this.showLoading();

                this.produtoSelected = null;

                let codigoProduto = this.codigoProduto;

                if (this.listFamiliasAux.length > 0) {
                    codigoProduto = ""
                }

                let listaProdutoBaseFilter = {
                    codigoProduto,
                    idProdutoFamilia: 0,
                    idTipoProduto: 0,
                    listCodigoParametroItem: ["'0'"],
                    listIdParametroItem: [0]
                }

                if (this.tipoProdutoSelected != null && this.tipoProdutoSelected != undefined) {
                    listaProdutoBaseFilter.idTipoProduto = this.tipoProdutoSelected.id;
                }

                if (this.familiaProdutoSelected != null && this.familiaProdutoSelected != undefined) {
                    listaProdutoBaseFilter.idProdutoFamilia = this.familiaProdutoSelected.id;
                }

                this.listaIDParametroItem = "";

                this.listParametro.forEach(itemParametro => {
                    if (itemParametro.itemSelected && itemParametro.itemSelected != null && itemParametro.itemSelected != undefined) {
                        listaProdutoBaseFilter.listCodigoParametroItem.push(`'${itemParametro.itemSelected.codigo}'`);
                        listaProdutoBaseFilter.listIdParametroItem.push(itemParametro.itemSelected.id);
                        this.listaIDParametroItem += `${itemParametro.itemSelected.codigo},`
                    }
                });

                this.listaIDParametroItem = this.listaIDParametroItem.substring(0, this.listaIDParametroItem.length - 1);

                this.listProduto = await this.$store.dispatch("produtoModule/ListarProdutoBase", listaProdutoBaseFilter);

                if (this.codigoProduto != null && this.codigoProduto != undefined && this.codigoProduto != "" && this.listFamiliasAux.length <= 0) {
                    this.grandezaSelected = null;
                    this.marcaSelected = null;
                    this.tipoProdutoSelected = null;
                    this.familiaProdutoSelected = null;
                    this.listParametro = [];
                }

                if (this.listProduto.length <= 0) {
                    this.verificarProdutoColado();
                }
                else {
                    this.verificaParametrosPreenchidos();
                }

                this.hideLoading();
            },

            async detalhesProduto(itemProduto) {
                this.produtoSelected = itemProduto;

                if (this.produtoSelected != null && this.produtoSelected != undefined) {

                    let response = await this.$store.dispatch("produtoModule/GetById", this.produtoSelected.idProduto);
                    this.listEspecificacao = response.result.listEspecificacaoResponse;

                    this.mostrarDetalhesProduto = true;
                }
            },

            async calcularPrecoCustoProduto() {

                this.montaJsonListaParametrosSelecionados();

                let precoCusto = 0;

                this.listParametro.forEach(itemParametro => {
                    if (itemParametro.itemSelected && itemParametro.itemSelected != null && itemParametro.itemSelected != undefined) {

                        precoCusto = (parseFloat(precoCusto) + parseFloat(itemParametro.itemSelected.precoCustoParametroItem)).toFixed(2);
                    }
                });

                this.precoCustoProdutoCalculado = precoCusto;
            },

            consultarProdutoKeyAccount(codigoProduto) {

                let result = {
                    keyAccount: false,
                    preco: 0
                };

                if (this.listProdutoKeyAccount != null && this.listProdutoKeyAccount != undefined && this.listProdutoKeyAccount.length > 0) {
                    let produtoKeyAccount = this.listProdutoKeyAccount.filter(key => key.codigoProduto == codigoProduto)[0];

                    if (produtoKeyAccount != null && produtoKeyAccount != undefined) {
                        result.keyAccount = true;
                        result.preco = produtoKeyAccount.preco;
                    }
                }

                return result;
            },

            precoFinalProduto(itemProduto) {

                itemProduto.keyAccount = 0;
                let margemSubEstrutura = 0;

                let precoFinal = parseFloat(itemProduto.precoCustoProduto) + parseFloat(this.precoCustoProdutoCalculado);

                if (itemProduto.listSubEstrutura != null && itemProduto.listSubEstrutura != undefined && itemProduto.listSubEstrutura.length > 0) {

                    if (this.familiaProdutoSelected != null && this.familiaProdutoSelected != undefined) {
                        margemSubEstrutura = 1 - ( parseFloat(this.familiaProdutoSelected.margem) / 100 );
                    }

                    itemProduto.listSubEstrutura.forEach(itemSub => {
                        if (itemSub.expressaoRegular != null && itemSub.expressaoRegular != undefined && itemSub.expressaoRegular != "" && this.jsonParametrosSelecionados != null && this.jsonParametrosSelecionados != '') {

                            let expr = itemSub.expressaoRegular.toString().replaceAll("=", "==");

                            // eslint-disable-next-line no-undef
                            let expression = compileExpression(expr);

                            let result = expression(JSON.parse(this.jsonParametrosSelecionados));

                            if (result == 1) {

                                let custo = parseFloat(itemSub.custo);

                                itemSub.regraAplicada = true;
                                itemSub.custoComMargemFormatted = formaterDecimalBR(Math.floor( (custo / parseFloat(margemSubEstrutura)) * this.og) / this.og);
                                precoFinal = (parseFloat(precoFinal) + custo).toFixed(2);
                            }
                        }
                    });
                }

                let resultKeyAccount = this.consultarProdutoKeyAccount(itemProduto.codigoCompleto);

                if (resultKeyAccount.keyAccount == true) {
                    precoFinal = resultKeyAccount.preco;
                    itemProduto.keyAccount = 1;
                }

                if (margemSubEstrutura <= 0) {
                    margemSubEstrutura = 1;
                }

                const newPrecoFinal = Math.floor( (precoFinal / parseFloat(margemSubEstrutura)) * this.og) / this.og;

                return "R$ " + formaterDecimalBR(newPrecoFinal);
            },

            async calcularPrecoCustoProdutoSemMargem(itemProduto) {

                let valorCustoSemMargem = 0;
                let valorCustoSemMargemResultado = 0;

                this.listParametro.forEach(itemParametro => {
                    if (itemParametro.itemSelected && itemParametro.itemSelected != null && itemParametro.itemSelected != undefined) {
                        valorCustoSemMargem = (parseFloat(valorCustoSemMargem) + parseFloat(itemParametro.itemSelected.precoCustoParametroItemSemMargem)).toFixed(2);
                    }
                });

                let listSubEstrutura = itemProduto.listSubEstrutura.filter(sub => sub.regraAplicada == true);

                if (listSubEstrutura == null || listSubEstrutura == undefined || listSubEstrutura.length <= 0) {
                    valorCustoSemMargemResultado = (parseFloat(valorCustoSemMargem) + parseFloat(itemProduto.precoCustoProdutoSemMargem));

                    if (valorCustoSemMargemResultado <= 0) {

                        let resultKeyAccount = this.consultarProdutoKeyAccount(itemProduto.codigoCompleto);

                        if (resultKeyAccount.keyAccount == true) {
                            valorCustoSemMargemResultado = resultKeyAccount.preco;
                        }
                    }
                }
                else {
                    listSubEstrutura.forEach(item => {
                        let custoSubEstrutura = parseFloat(item.custo);
                        valorCustoSemMargemResultado += custoSubEstrutura;
                    })
                }
                
                valorCustoSemMargemResultado = Math.floor(valorCustoSemMargemResultado * this.og) / this.og;

                return valorCustoSemMargemResultado;
            },

            OK() {
                this.mostrarDetalhesProduto = false;
            },

            calcularMargemEfetiva(itemProduto) {

                let valorCustoSemMargem = parseFloat(itemProduto.valorCustoSemMargem) * itemProduto.qtde;
                let precoSemImpostos = parseFloat(formaterDecimalBRServer(itemProduto.precoSemImpostos)) * itemProduto.qtde;

                let margemEfetiva = Math.floor((((precoSemImpostos - valorCustoSemMargem) / precoSemImpostos) * 100) * this.og) / this.og;

                return `${formaterDecimalBR(margemEfetiva)} %` ;
            },

            async adicionarCarrinho(item) {

                if (this.todosParametrosPreenchidos == false) {
                    this.showToast("error", "Aviso!", `É necessário preencher todos os parametros!`);
                }
                else {
                    const precoCusto = this.precoFinalProduto(item);
                    let valorCustoSemMargem = await this.calcularPrecoCustoProdutoSemMargem(item);
                    const margemEfetiva = this.calcularMargemEfetiva(item);

                    if (valorCustoSemMargem == null || valorCustoSemMargem == undefined || valorCustoSemMargem <= 0) {
                        valorCustoSemMargem = formaterDecimalBRServer(precoCusto);
                    }

                    const icpProduto = this.valorTotalParametrosSelecionados(item, true);

                    let certificacaoEX = 0;
                    let familiaEX = item.familiaEX;

                    if (this.listParametro != null && this.listParametro != undefined && this.listParametro.length > 0) {
                        certificacaoEX = this.listParametro.filter(item => item.itemSelected != null && item.itemSelected != undefined && item.itemSelected.certificacaoEX == 1).length > 0 ? 1 : 0;
                    }
                    
                    if (certificacaoEX == 0) {
                        certificacaoEX = item.certificacaoEX;
                    }

                    if (certificacaoEX == 1 && (familiaEX == null || familiaEX == undefined || familiaEX == '')) {
                        if (this.familiaProdutoSelected != null && this.familiaProdutoSelected != undefined) {
                            familiaEX = this.familiaProdutoSelected.codigoFamilia;
                        }
                    }

                    const itemProduto = {
                        id: item.id,
                        idProduto: item.idProduto,
                        idMarca: item.idMarca,
                        imagem: item.imagem,
                        codigoProduto: item.codigoCompleto,
                        descricao: item.descricao,
                        listaIDParametroItem: this.listaIDParametroItem,
                        desconto: "0,00",
                        qtde: 1,
                        moedaProduto: "R$",
                        margemEfetiva: margemEfetiva,
                        precoSemImpostos: precoCusto,
                        precoUnitarioComImpostos: precoCusto,
                        precoCusto: precoCusto,
                        precoCustoOriginal: precoCusto,
                        precoCustoTotal: precoCusto,
                        precoCustoProdutoSemMargem: valorCustoSemMargem,
                        codigoNCM: item.codigoNCM,
                        aliqIPI: 0,
                        aliqIPIFormatted: "0,00",
                        aliqPIS: 0,
                        aliqPISFormatted: "0,00",
                        aliqCOFINS: 0,
                        aliqCOFINSFormatted: "0,00",
                        aliqICMS: 0,
                        aliqICMSFormatted: "0,00",
                        importado: item.importado,
                        origem: item.paisCidadeDescricao,
                        descontoCampanha: false,
                        descontoAplicado: false,
                        keyAccount: item.keyAccount,
                        prazoEntregaProdutoDefinido: item.prazoEntregaDefinido,
                        prazoEntregaProduto: item.prazoEntrega,
                        compraMinima: item.compraMinima,
                        compraMinimaQtde: item.compraMinimaQtde,
                        idCambioICP: icpProduto.idCambioICP,
                        valorTotalICP: icpProduto.valorTotalICP,
                        familiaEX,
                        certificacaoEX,
                        valorCertificacaoEX: 0,
                        valorCertificacaoEXFormatted: 0,
                        produtoAgrupado: 0,
                        tagAgrupamento: '',
                        listPrazoEntrega: item.listPrazoEntrega
                    }

                    const response = await this.$store.dispatch("carrinhoModule/CreateUpdate", { itemProduto });

                    if (response.success) {
                        this.carrinhoStateGetters.reload = !this.carrinhoStateGetters.reload;
                        this.showToast("success", "Sucesso!", response.message);
                    }
                }
            },

            async getLists() {
                
                let listGrandeza = await this.$store.dispatch("grandezaModule/ListOnlyActive");

                this.listGrandeza = listGrandeza.filter(grd => grd.comercializar == this.userLoggedGetters.usuarioInterno == 1 ? grd.comercializar : 1);

                if (this.userLoggedGetters.usuarioInterno != 1 && this.userLoggedGetters.distribuidorSelected != null && this.userLoggedGetters.distribuidorSelected != undefined) {
                    let idCliente = this.userLoggedGetters.distribuidorSelected.id;

                    this.listProdutoKeyAccount = await this.$store.dispatch("keyAccountModule/ListarProdutos", idCliente);
                }
            },

            async novaBusca() {
                this.codigoProduto = "";
                this.grandezaSelected = null;
                this.marcaSelected = null;
                this.tipoProdutoSelected = null;
                this.listParametro = [];
                this.listProduto = [];
                this.listFamilias = [];
                this.listFamiliasAux = [];
                this.familiaProdutoSelected = null;
                this.precoCustoProdutoCalculado = 0;
            },

            async confirmarSolicitacaoCadastroProduto() {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmação',
                    bodyText: `Confirma a solicitação de cadastro do produto ${this.codigoProduto}?`,
                    methodAcao1: ( () => {} ),
                    methodConfirm: this.solicitarCadastroProduto,
                    params: 0
                };
            },

            async solicitarCadastroProduto() {

                this.showLoading();

                let requestSolicitacao = {
                    codigoProduto: this.codigoProduto
                }

                let response = await this.$store.dispatch("produtoModule/SolicitarCadastro", requestSolicitacao);

                    if (response.success) {
                        this.showToast("success", "Sucesso!", response.message);
                    }

                this.hideLoading();
            }
        },

        async created() {
            this.showLoading();

            await this.getLists();

            this.hideLoading();
        }
    })
</script>


<style>
    .v-list-item--disabled {
        pointer-events: auto !important;
    }
</style>

<style scoped>
    .detalhes-produto {
        width: 30%; 
        display: flex; 
        flex-direction: row;
        margin-bottom: 10px;
    }
</style>